import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

@Injectable()

export class ViewcoursesService {

	getData:string;

  constructor(private _http:Http) { }

	getCurrentPosts(){

	  return this._http.get('http://tscedu.co.in/api/display.php').map(res => res.json());

	}

	deletecourse(id){

	  return this._http.post('http://tscedu.co.in/api/display.php',{'delid':id}).map(res => res.json());

	}

}
