import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-adminlogin',
  templateUrl: './adminlogin.component.html',
  styleUrls: ['./adminlogin.component.css']
})
export class AdminloginComponent implements OnInit {

  email:string;
  password:string;
  constructor(private router:Router) { }

  ngOnInit() {
  }

  submitlogin(email,password){

    if(email == 'admin' && password == 'admin@12345'){
      localStorage.setItem('admin_id','e6e061838856bf47e1de730719fb2609');
      this.router.navigate(['/admin/dashboard']);
    }else{
      alert('Wrong Credentials! Try Again..');
    }

  }
}
