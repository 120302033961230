import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ffooter',
  templateUrl: './ffooter.component.html',
  styleUrls: ['./ffooter.component.css']
})
export class FfooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
