import { Component, OnInit } from '@angular/core';
import {ContactusService } from './contactus.service'

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css'],
  providers:[ContactusService]
})
export class ContactusComponent  {

  c_name:string;
  state:string;
  district:string;
  address:string;
  city:string;
  mobile:string;
  pin:string;
  email:string;
  getData:string;
  ins_name:string;
  
  constructor(private _httpService:ContactusService) { }

  conatcthere() {

  var formdata = new FormData();

  formdata.append('c_name',this.c_name);
  formdata.append('state',this.state);
  formdata.append('district',this.district);
  formdata.append('address',this.address);
  formdata.append('city',this.city);
  formdata.append('mobile',this.mobile);
  formdata.append('pin',this.pin);
  formdata.append('email',this.email);
  formdata.append('ins_name',this.ins_name);
  
    this._httpService.conatcthere(formdata).
    subscribe(
        data => this.getData = JSON.stringify(data.status),
        error => alert(error),
      () => console.log("Complete")
     )

  }

}
