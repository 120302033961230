import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

@Injectable()

export class LoginService {

	getData:string;

  constructor(private _http:Http) { }

  loginhere(email,password){
    return this._http.post('http://tscedu.co.in/api/login.php',{'email':email,'password':password}).map(res => res.json());

  }

}
