import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

@Injectable()

export class CarrierService {

	getData:string;

  constructor(private _http:Http) { }

  getcar(){
    return this._http.get('http://tscedu.co.in/api/career.php').map(res => res.json());

  }

}
