import { Component, OnInit } from '@angular/core';
import { LoginService } from './login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers:[LoginService]
})
export class LoginComponent {

  constructor(private _httpService:LoginService,private router:Router) { }

  email:string;
  password:string;
  getData:string;

  islogginerror:boolean = false;
  submitlogin(){

    var email = this.email;
    var password = this.password;

    this._httpService.loginhere(email,password).
    subscribe(
        (data : any) =>  {this.getData = data.status
          localStorage.setItem('user_id',data.id);
          this.router.navigate(['/franchise/details']);
        },
        error => {
           this.islogginerror = true; 
        },
        () => console.log("Complete")

    )


  }
}
