import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sociallinks',
  templateUrl: './sociallinks.component.html',
  styleUrls: ['./sociallinks.component.css']
})
export class SociallinksComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
