import { Component, OnInit } from '@angular/core';
import{ViewcareerService} from './viewcareer.service'

@Component({
  selector: 'app-viewcareer',
  templateUrl: './viewcareer.component.html',
  styleUrls: ['./viewcareer.component.css'],
  providers:[ViewcareerService]
})
export class ViewcareerComponent implements OnInit {
  getData:string;

  constructor(private _httpService:ViewcareerService) { }

  ngOnInit() {

    this._httpService.getcar()
    .subscribe(
       data => this.getData = data,
      error => alert(error),
      () => console.log("Complete")
    )


  }

  refresh (){

    location.reload();
  }

  removecareer(id){

    this._httpService.deletecareer(id)
    .subscribe(
      data => alert(data.status),
      error => alert(error),
      () => this.refresh()
    
    )
    
    
    
  }

}
