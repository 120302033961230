import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

@Injectable()

export class AddcarrerService {

	getData:string;

  constructor(private _http:Http) { }

  addcar(car_name,car_desc){
    return this._http.post('http://tscedu.co.in/api/career.php',{"career_name":car_name,"career_description":car_desc}).map(res => res.json());
  }

}
