import { Component, OnInit } from '@angular/core';
import { RegsterfrancService } from './registerfranc.service';

@Component({
  selector: 'app-registerfranc',
  templateUrl: './registerfranc.component.html',
  styleUrls: ['./registerfranc.component.css'],
  providers:[RegsterfrancService]
})

export class RegisterfrancComponent {
  full_name:string;
  email:string;
  password:string;
  address:string;
  state:string;
  district:string;
  city:string;
  pin:string;
  mobile:string;
  getData:string;

  constructor(private _httpSevice:RegsterfrancService) { }

refresh(){

  location.reload();
}

  registerform(){

    const fd = new FormData();
		
    fd.append("full_name",this.full_name);
    fd.append("email",this.email);
    fd.append("password",this.password);
    fd.append("address",this.address);
    fd.append("state",this.state);
    fd.append("district",this.district);
    fd.append("city",this.city);
    fd.append("pin",this.pin);
    fd.append("mobile",this.mobile);
    
    this._httpSevice.addfranchise(fd)
    .subscribe(
      data => alert(data.status),
      error => alert(error),
      () => this.refresh()
    
    )

  }
  

}
