import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

@Injectable()

export class ViewfrancService {

	getData:string;

  constructor(private _http:Http) { }

	getfranc(){

	  return this._http.get('http://tscedu.co.in/api/franchise.php').map(res => res.json());

	}

	deletefran(id){

	  return this._http.post('http://tscedu.co.in/api/franchise.php',{'delid':id}).map(res => res.json());

	}

  updatefranchise(fd){

	  return this._http.post('http://tscedu.co.in/api/franchise.php',fd).map(res => res.json());

	}

}
