import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

@Injectable()

export class FranchdashboardService {

	getData:string;

  constructor(private _http:Http) { }

  getstudentdetails(user_id){

	  return this._http.post('http://tscedu.co.in/api/student.php',{'user_id':user_id}).map(res => res.json());

	}

	deletestudent(id){

		return this._http.post('http://tscedu.co.in/api/student.php',{'delid':id}).map(res => res.json());

		}

		updatestudent(fd){

			return this._http.post('http://tscedu.co.in/api/student.php',fd).map(res => res.json());

		}

}
