import { Component, OnInit } from '@angular/core';
import { ViewDashboardService } from './dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers:[ViewDashboardService]
})
export class DashboardComponent implements OnInit {

  serarch_franch:string;
  getData:string;
  user_id:string;
  date:string;
  nodata:boolean = false;

  constructor(private _httpservice:ViewDashboardService) { }

  ngOnInit() {

   this.nodata = false;

  }

  onfanchSearch(){

    var user_id = localStorage.getItem('user_id');

    const fd = new FormData();
    
    fd.append('user_id',user_id);
    fd.append('search_param',this.serarch_franch);
    fd.append('from_date',this.date);
    

    this._httpservice.getfrancStud(fd).subscribe(
      (data : any) =>  {this.getData = data },
      error =>  this.nodata = true,
      () => console.log('Complete')
     )

  }


}
