import { Component, OnInit } from '@angular/core';
import { ViewcoursesService } from './viewcourses.service';

@Component({
  selector: 'app-viewcourses',
  templateUrl: './viewcourses.component.html',
  styleUrls: ['./viewcourses.component.css'],
  providers: [ViewcoursesService]
})
export class ViewcoursesComponent implements OnInit {

getData : string;

  constructor(private _httpSevice:ViewcoursesService) { }

  ngOnInit() {
	    this._httpSevice.getCurrentPosts()
			.subscribe(
				data => this.getData = data,
				error => alert(error),
				() => console.log("Complete")
			
			)
  }

  refresh (){

    location.reload();
  }

  removecourse(id){

    this._httpSevice.deletecourse(id)
    .subscribe(
      data => alert(data.status),
      error => alert(error),
      () => this.refresh()
    
    )

  }


}
