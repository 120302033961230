import { Component, OnInit } from '@angular/core';
import { AddcertificateService } from './addcertificate.service';

@Component({
  selector: 'app-addcertificates',
  templateUrl: './addcertificates.component.html',
  styleUrls: ['./addcertificates.component.css'],
  providers:[AddcertificateService]
})
export class AddcertificatesComponent  {

	cert_name : string;
	cert_id : string;
  selectedFile : File = null;
  student_id : string;
  getData:string;
	

  constructor(private _httpSevice:AddcertificateService) { }

	onFileSelected(event){
		this.selectedFile = <File> event.target.files[0];
	}

	refresh(){

		location.reload();
	}


	onUpload(){
		
		const fd = new FormData();
		
		fd.append("cert_name",this.cert_name);
    fd.append("cert_id",this.cert_id);
    fd.append("student_id",this.student_id);
  	fd.append("file",this.selectedFile,this.selectedFile.name);
		
		this._httpSevice.addcert(fd)
			.subscribe(
				data => alert(data.status), //this.getData = JSON.stringify(data.status),
				error => alert(error),
				() => this.refresh()
			
			)
			
		
	}

}
