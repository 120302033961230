import { Component, OnInit } from '@angular/core';
import {SearchresultService } from './searchresult.service'

@Component({
  selector: 'app-searchresult',
  templateUrl: './searchresult.component.html',
  styleUrls: ['./searchresult.component.css'],
  providers:[SearchresultService]
})
export class SearchresultComponent  {

  getData:string;
  name:string;
  dob:string;
  status :string;

  constructor(private _httpservice:SearchresultService) { }

  onsearch(){

    const fd = new FormData();

    fd.append("fullname",this.name);
    fd.append("dob",this.dob);

    this._httpservice.getcerts(fd)
    .subscribe(
      data => this.getData = data,
      error => alert(error),
      () => console.log("Complete")

    )

  }

}
