import { Component, OnInit } from '@angular/core';
import { ViewfrancService } from './viewfranc.service';

@Component({
  selector: 'app-viewfranc',
  templateUrl: './viewfranc.component.html',
  styleUrls: ['./viewfranc.component.css'],
  providers:[ViewfrancService]
})
export class ViewfrancComponent implements OnInit {

  getData:string;
  editdata:string;
  editdatastatus :boolean;

  constructor(private _httpservice:ViewfrancService) { }

  ngOnInit() {


    this._httpservice.getfranc()
    .subscribe(
      data => this.getData = data,
      error => alert(error),
      () => console.log("Complete")

    )

  }

  refresh (){

    location.reload();
  }

  editfra(exe){
    this.editdatastatus = true;
    this.editdata = exe;

  }


  Savefradata(savedata){

    const fd = new FormData();

    fd.append('type','update');
    fd.append('id',savedata.id);
    fd.append('full_name',savedata.full_name);
    fd.append('email',savedata.email);
    fd.append('address',savedata.address);
    fd.append('state',savedata.state);
    fd.append('district',savedata.district);
    fd.append('city',savedata.city);
    fd.append('pin',savedata.pin);
    fd.append('mobile',savedata.mobile);

    this._httpservice.updatefranchise(fd).subscribe(
      data =>  alert(data.status),
      error => alert(error),
      () => this.refresh()
    )

  }

  removefra(id){

    this._httpservice.deletefran(id)
    .subscribe(
      data => alert(data.status),
      error => alert(error),
      () => this.refresh()

    )

  }

}
