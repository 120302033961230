import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

@Injectable()

export class ViewcareerService {

	getData:string;

  constructor(private _http:Http) { }

  

  getcar(){
    return this._http.get('http://tscedu.co.in/api/career.php').map(res => res.json());

  }

  deletecareer(id){
	  
	  return this._http.post('http://tscedu.co.in/api/career.php',{'delid':id}).map(res => res.json());
    
	}

}
