import { Component, OnInit } from '@angular/core';
import { FrandetailsService } from './frandetails.service';


@Component({
  selector: 'app-frandetails',
  templateUrl: './frandetails.component.html',
  styleUrls: ['./frandetails.component.css'],
  providers: [FrandetailsService]
})
export class FrandetailsComponent implements OnInit {

 constructor(private _httpService:FrandetailsService) { }
  getData:string;
  
  ngOnInit() {

    var user_id =  localStorage.getItem('user_id');

    this._httpService.getfrancdetails(user_id).subscribe(
      data => this.getData = data,
      error => alert(error),
      () => console.log('complete')

    )

  }

}
