import { Component } from '@angular/core';
import {AddcarrerService} from './addcarrer.service'

@Component({
  selector: 'app-addcarrer',
  templateUrl: './addcarrer.component.html',
  styleUrls: ['./addcarrer.component.css'],
  providers:[AddcarrerService]
})
export class AddcarrerComponent {

  career_name :string;
  career_description:string;
  getData:string;

  constructor(private _httpService:AddcarrerService) { }

  refresh(){
    location.reload();
  }


  onaddcareer() {

   var car_name = this.career_name;
   var car_desc=  this.career_description;

    this._httpService.addcar(car_name,car_desc)
			.subscribe(
				data => alert(data.status),
				error => alert(error),
				() => this.refresh()
			
			)
    

  }

}
