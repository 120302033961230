import { Component, OnInit } from '@angular/core';
import { CoursesService } from './courses.service';


@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css'],
  providers: [CoursesService]
})

export class CoursesComponent {
	
	getData : string;

  constructor(private _httpSevice:CoursesService) {  }

  ngOnInit() {
	  
	  this._httpSevice.getCurrentPosts()
			.subscribe(
				data => this.getData = data,
				error => alert(error),
				() => console.log("Complete")
			
			)
	}
  
  
  
 
}
