import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

@Injectable()

export class ViewDashboardService {

	getData:string;

  constructor(private _http:Http) { }

  getfrancStud(fd){
    return this._http.post('http://tscedu.co.in/api/franchise.php',fd).map(res => res.json());
  }


}
