import { Component, OnInit } from '@angular/core';
import { AddstudentService } from './addstudent.service';

@Component({
  selector: 'app-addstudent',
  templateUrl: './addstudent.component.html',
  styleUrls: ['./addstudent.component.css'],
  providers:[AddstudentService]
})
export class AddstudentComponent implements OnInit {

  courseregid : string;
  f_name : string;
  m_name : string;
  l_name : string;
  dob : string;
  qualification : string;
  email : string;
  mobile : string;
  course_applied_for : string;
  gender : string;
  address : string;
  city : string;
  pin : string;
  state : string;
  country : string;
  getData :string;
  female:string;
  male:string;
  user_id:string;
  selectedFile : File = null;
  size:  string;

  constructor(private _httpService:AddstudentService) { }

  ngOnInit() {

    this._httpService.getCurrentPosts()
      .subscribe(
        data => this.getData = data,
        error => alert(error),
        () => console.log(this.getData)

      );


  }

  onFileSelected(event){

    var size = event.target.files[0]['size'];

    if(size > 32000){
      alert('Image Size Must be less than 30kb');
      this.selectedFile = null;
      return false;
    }else{
      this.selectedFile = <File> event.target.files[0];
    }


  }

  refresh(){
    location.reload();

  }

  onUpload(){

    if(this.selectedFile == null){
      alert('Image Size Must be less than 30kb');
      return false;
    }

    var user_id = localStorage.getItem('user_id');
    const fd = new FormData();

    fd.append('user_id',user_id);
    fd.append('courseregid',this.courseregid);
    fd.append('f_name',this.f_name);
    fd.append('m_name',this.m_name);
    fd.append('l_name',this.l_name);
    fd.append('dob',this.dob);
    fd.append('qualification',this.qualification);
    fd.append('email',this.email);
    fd.append('mobile',this.mobile);
    fd.append('course_applied_for',this.course_applied_for);
    fd.append('gender',this.gender);
    fd.append('address',this.address);
    fd.append('city',this.city);
    fd.append('pin',this.pin);
    fd.append('state',this.state);
    fd.append('country',this.country);
  	fd.append("file",this.selectedFile,this.selectedFile.name);

    this._httpService.addstd(fd).subscribe(
       data =>  alert(data.status),
       error => alert(error),
       () => this.refresh()


    )

  }


}
