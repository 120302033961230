import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

@Injectable()

export class ViewcertificatesService {

	id :string;
  constructor(private _http:Http) { }

	getCurrentPosts(){
	  
	  return this._http.get('http://tscedu.co.in/api/admin_add_cert.php').map(res => res.json());
	
	}

	deletecert(id){
	  
	  return this._http.post('http://tscedu.co.in/api/admin_add_cert.php',{'delid':id}).map(res => res.json());
	
	}

}
