import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

@Injectable()

export class AddcertificateService {

	getData:string;

  constructor(private _http:Http) { }

  addcert(fd){
	  
    return this._http.post('http://tscedu.co.in/api/admin_add_cert.php',fd).map(res => res.json());
  
  }

 
  
}
