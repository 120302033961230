import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

@Injectable()

export class ContactusService {

	getData:string;

  constructor(private _http:Http) { }

  conatcthere(fd){
    return this._http.post('http://tscedu.co.in/api/contact_us.php',fd).map(res => res.json());

  }

}
