import { Component, OnInit } from '@angular/core';
import { ViewcertificatesService } from './viewcertificates.service';



@Component({
  selector: 'app-viewcertificates',
  templateUrl: './viewcertificates.component.html',
  styleUrls: ['./viewcertificates.component.css'],
  providers: [ViewcertificatesService]
})

export class ViewcertificatesComponent implements OnInit {

  

  id:string;
  getData:string;
  constructor(private _httpSevice:ViewcertificatesService) { }

  ngOnInit() {

    this._httpSevice.getCurrentPosts()
    .subscribe(
      data => this.getData = data,
      error => alert(error),
      () => console.log("Complete")
    
    )

  }

  refresh (){

    location.reload();
  }

  removecert(id){

    this._httpSevice.deletecert(id)
    .subscribe(
      data => alert(data.status),
      error => alert(error),
      () => this.refresh()
    
    )

  }

}
