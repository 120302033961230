import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FranchdashboardService } from './franch-dashboard.service';

@Component({
  selector: 'app-franch-dashboard',
  templateUrl: './franch-dashboard.component.html',
  styleUrls: ['./franch-dashboard.component.css'],
  providers:[FranchdashboardService]
})
export class FranchDashboardComponent implements OnInit {

  courselists;
  user_id:string;
  getData:string;
  editdata:string;
  editdatastatus :boolean;
  constructor(private router:Router,private _httpService:FranchdashboardService) { }


  ngOnInit() {

    this.editdatastatus = false;

  var user_id =  localStorage.getItem('user_id');

    this._httpService.getstudentdetails(user_id).subscribe(
      data => this.getData = data,
      error => alert(error),
      () => console.log('complete')

    )

  }

  refresh(){
    location.reload();

  }

  editstud(exe){
    this.editdatastatus = true;
    this.editdata = exe;

  }

  Savestuddata(savedata){

    const fd = new FormData();

    fd.append('type','update');
    fd.append('id',savedata.id);
    fd.append('courseregid',savedata.courseregid);
    fd.append('f_name',savedata.f_name);
    fd.append('m_name',savedata.m_name);
    fd.append('dob',savedata.dob);
    fd.append('qualification',savedata.qualification);
    fd.append('email',savedata.email);
    fd.append('mobile',savedata.mobile);
    fd.append('course_applied_for',savedata.course_applied_for);
    fd.append('gender',savedata.gender);
    fd.append('address',savedata.address);
    fd.append('city',savedata.city);
    fd.append('pin',savedata.pin);
    fd.append('state',savedata.state);
    fd.append('country',savedata.country);

    this._httpService.updatestudent(fd).subscribe(
       data =>  alert(data.status),
       error => alert(error),
       () => this.refresh()


    )

  }

  removestudent(id){

    this._httpService.deletestudent(id)
    .subscribe(
      data => alert(data.status),
      error => alert(error),
      () => this.refresh()

    )

  }

  addcoursecart(){



  }

  Logout(){

    localStorage.removeItem('user_id');
    this.router.navigate(['/login']);
  }

}
