import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

@Injectable()

export class FrandetailsService {

	getData:string;

  constructor(private _http:Http) { }

  getfrancdetails(user_id){
	  
	  return this._http.post('http://tscedu.co.in/api/franchise.php',{'franc_id':user_id}).map(res => res.json());
	  
	}

}
