import { Component, OnInit } from '@angular/core';
import {CarrierService} from './carrier.service'

@Component({
  selector: 'app-carrier',
  templateUrl: './carrier.component.html',
  styleUrls: ['./carrier.component.css'],
  providers:[CarrierService]
})
export class CarrierComponent implements OnInit {

  getData:string;
  constructor(private _httpService:CarrierService) { }

  ngOnInit() {

    this._httpService.getcar()
    .subscribe(
       data => this.getData = data,
      error => alert(error),
      () => console.log("Complete")
    )

  }

}
