import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule} from '@angular/router';

import { AppComponent } from './app.component';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { CoursesComponent } from './components/courses/courses.component';
import { SearchresultComponent } from './components/searchresult/searchresult.component';
import { SociallinksComponent } from './components/sociallinks/sociallinks.component';
import { ContactusComponent } from './components/contactus/contactus.component';
import { RegisterfranchiseComponent } from './components/registerfranchise/registerfranchise.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './Admin/dashboard/dashboard.component';
import { HeaderComponent } from './Admin/header/header.component';
import { FranchiseComponent } from './components/franchise/franchise.component';
import { MainheaderComponent } from './components/mainheader/mainheader.component';
import { MainfooterComponent } from './components/mainfooter/mainfooter.component';
import { FooterComponent } from './Admin/footer/footer.component';
import { AdminmainComponent } from './Admin/adminmain/adminmain.component';
import { LayoutComponent } from './components/layout/layout.component';
import { AdminlayoutComponent } from './Admin/adminlayout/adminlayout.component';
import { AddcoursesComponent } from './Admin/addcourses/addcourses.component';
import { ViewcoursesComponent } from './Admin/viewcourses/viewcourses.component';
import { AddcertificatesComponent } from './Admin/addcertificates/addcertificates.component';
import { ViewcertificatesComponent } from './Admin/viewcertificates/viewcertificates.component';
import { RegisterfrancComponent } from './Admin/registerfranc/registerfranc.component';
import { ViewfrancComponent } from './Admin/viewfranc/viewfranc.component';
import { CarrierComponent } from './components/carrier/carrier.component';
import { AddcarrerComponent } from './Admin/addcarrer/addcarrer.component';
import { AddstudentComponent } from './franmain/addstudent/addstudent.component';
import { FheaderComponent } from './franmain/fheader/fheader.component';
import { FfooterComponent } from './franmain/ffooter/ffooter.component';
import { FranmainfolderComponent } from './franmain/franmainfolder/franmainfolder.component';
import { FranchDashboardComponent } from './franmain/franch-dashboard/franch-dashboard.component';
import { ViewcareerComponent } from './Admin/viewcareer/viewcareer.component'
import { AuthGuard } from './auth/auth.guard';
import { AdminloginComponent } from './Admin/adminlogin/adminlogin.component';
import { AdminauthGuard } from './adminauth/adminauth.guard';
import { FrandetailsComponent } from './franmain/frandetails/frandetails.component';



@NgModule({
  declarations: [
    AppComponent,
    AboutusComponent,
    HomepageComponent,
    CoursesComponent,
    SearchresultComponent,
    SociallinksComponent,
    ContactusComponent,
    RegisterfranchiseComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    FranchiseComponent,
    MainheaderComponent,
    MainfooterComponent,
    FooterComponent,
    AdminmainComponent,
    LayoutComponent,
    AdminlayoutComponent,
    AddcoursesComponent,
    ViewcoursesComponent,
    AddcertificatesComponent,
    AddcertificatesComponent,
    ViewcertificatesComponent,
    RegisterfrancComponent,
    ViewfrancComponent,
    CarrierComponent,
    AddcarrerComponent,
    AddstudentComponent,
    FheaderComponent,
    FfooterComponent,
    FranmainfolderComponent,
    FranchDashboardComponent,
    ViewcareerComponent,
    AdminloginComponent,
    FrandetailsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
	
    RouterModule.forRoot(
	[
	{path:'',redirectTo:'home', pathMatch: 'full' },
      {
         path: '',
         component: LayoutComponent,
         children : [
            {
        path : 'home',
        component : HomepageComponent
      },
            {
        path : 'Aboutus',
        component : AboutusComponent
      },
      {
        path : 'Courses',
        component : CoursesComponent
      },
      {
        path : 'Verfication-Certificate',
        component : SearchresultComponent
      },
      {
        path : 'ContactUs',
        component : ContactusComponent
      },
      {
        path : 'franchise-register',
        component : RegisterfranchiseComponent
      },
      {
        path : 'franchise',
        component : FranchiseComponent
      },
      {
        path : 'login',
        component : LoginComponent
      },
      {
        path : 'career',
        component : CarrierComponent
      },
      {
        path : 'admin/login',
        component : AdminloginComponent
      }
         ]
      },

      {
        

        path : '',
        component : AdminlayoutComponent,
        children : [
           
            {
              path : 'admin/dashboard',
              component : DashboardComponent,
              canActivate : [AdminauthGuard]
            },
            {
              path : 'admin/viewcertificates',
              component : ViewcertificatesComponent,
              canActivate : [AdminauthGuard]
            },
            {
              path : 'admin/addcertificates',
              component : AddcertificatesComponent,
              canActivate : [AdminauthGuard]
            },
            {
              path : 'admin/viewcourses',
              component : ViewcoursesComponent,
              canActivate : [AdminauthGuard]
            },
            {
              path : 'admin/addcourses',
              component : AddcoursesComponent,
              canActivate : [AdminauthGuard]
            },
            {
              path : 'admin/createfrach',
              component : RegisterfrancComponent,
              canActivate : [AdminauthGuard]
            },
            {
              path : 'admin/viewfrach',
              component : ViewfrancComponent,
              canActivate : [AdminauthGuard]
            },
            {
              path : 'admin/career',
              component : AddcarrerComponent,
              canActivate : [AdminauthGuard]
            },
            {
              path : 'admin/viewcareer',
              component : ViewcareerComponent,
              canActivate : [AdminauthGuard]
            }
        ]
        },
        {
          path : '',
          component : FranmainfolderComponent,
          children : [
              {
                path : 'franchise/dashboard',
                component : FranchDashboardComponent,
                canActivate : [AuthGuard]
              },
              {
                path : 'franchise/Add',
                component : AddstudentComponent,
                canActivate : [AuthGuard]
              },
              {
                path : 'franchise/details',
                component : FrandetailsComponent,
                canActivate : [AuthGuard]
              }

              
          ]
          }
   ])
  ],
  providers: [AuthGuard,AdminauthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
