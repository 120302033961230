import { Component } from '@angular/core';
import { AddcoursesService } from './addcourses.service';

@Component({
  selector: 'app-addcourses',
  templateUrl: './addcourses.component.html',
  styleUrls: ['./addcourses.component.css'],
  providers: [AddcoursesService]
})

export class AddcoursesComponent {


	coursetitle : string;
	courseid : string;
 selectedFile : File = null;
	getData:string;


  constructor(private _httpSevice:AddcoursesService) { }

	onFileSelected(event){
		this.selectedFile = <File> event.target.files[0];
	}

	refresh(){

		location.reload();

	}

	onUpload(){

		const fd = new FormData();

		fd.append("course_id",this.courseid);
		fd.append("course_name",this.coursetitle);
		if(this.selectedFile){
		  fd.append("file",this.selectedFile,this.selectedFile.name);
    }
		this._httpSevice.addcourse(fd)
			.subscribe(
				data => alert(data.status),
				error => alert(error),
				() => this.refresh()

			)

	}

}
